import { Input, Label } from "reactstrap";
import { getServiceHosts } from "./helpers";

export default (props) => {
  const { formContext } = props;
  const { ticketDetails, formData = {} } = formContext;
  const { attributes: serviceAttributes = {} } = ticketDetails;
  const { cluster = {} } = formData;
  const defaultDnsName = `${cluster?.dnsName?.toLowerCase()}.${cluster?.nameserver}`;
  const { serviceHosts } = getServiceHosts(defaultDnsName, serviceAttributes);
  return (
    <div className="dns-widget">
      {serviceHosts.map((appItem, idx) => (
        <div key={appItem?.appId} className={idx === 0 ? "" : "mt-3"}>
          <Label>{appItem?.appTitle}</Label>
          <Input value={appItem?.host} disabled />
        </div>
      ))}
    </div>
  );
};
